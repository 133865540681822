@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Calendar
  width: 100%
  border-radius: $border-radius-md
  border: 2px solid $background-accent

  .CurrentDay::after
    display: block
    content: ''
    width: 6px
    height: 6px
    position: absolute
    bottom: -6px
    left: 50%
    z-index: 1
    background-color: $background-primary
    border-radius: 50%
    transform: translateX(-50%)

  :global(.DayPicker-wrapper)
    margin-top: 8px
    margin-bottom: 36px
    overflow: hidden

  +mediamax(xs)
    :global(.DayPicker-Months), :global(.DayPicker-NavBar)
      margin: 0 -12px

