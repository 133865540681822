@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Alert
    +stack(8px, row, center, center)
    padding: 16px

    .Icon
        flex-shrink: 0

    .Content
        flex: 1 1 auto
