@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.discount-form
  .input__input:disabled, .multi_menu__input--disabled, .select-simple--disabled .select-simple__single-value, .radio_group_disabled span
    color: $color-text-secondary

  .radio_group_disabled span:not(.radio_group__item_active)
    color: $color-text-tertiary

  .DayPicker-Day
    &:not(.DayPicker-Day--from, .DayPicker-Day--to)
      .datepicker-day
        &::after
          margin-left: -3px
          height: 28px!important
          background: $background-accent none!important
          z-index: 0!important
