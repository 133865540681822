@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.wrapper
  padding: 8px 24px

.item
  display: flex
  justify-content: space-between
  align-items: center
  gap: 0 16px
  padding: 18px 0

  &__title
    @extend %body
    word-break: break-word

.result
  width: 100%

.hasValue
  & > svg
    +mediamax(xs)
      transform: translateY(-100%)
