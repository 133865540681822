@import "@russpass/partner-front-ui/build/assets/styles/common/vars"

.notificationContainer
    position: absolute
    background-color: $background-floating
    border: 2px solid $color-hint-error
    top: 60px
    left: 15px
    padding: 8px 12px
    border-radius: $border-radius-md
    width: 244px
    height: 52px
    z-index: 1
    cursor: default
    display: flex
    align-items: center

    .notificationContent
        margin: 0
        font-size: 14px
        font-weight: 500
        line-height: 18px
        font-family: $font-family-suisse-medium
