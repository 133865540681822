@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.error
  text-align: center
  margin-top: 80px
  &__title
    @extend %section
    margin-bottom: 8px
  &__description
    @extend %footnote
    color: $color-text-secondary
    margin-bottom: 40px

.status
  color: $color-hint-success


