@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.OtpWrapper
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 166px

    .CloseButton
        position: absolute
        top: 46px
        left: 44px

    &.Compact
        padding-top: 48px

        .CloseButton
            top: 6px
            left: 10px


