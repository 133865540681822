@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Header
    position: relative
    @extend %section
    text-align: center
    margin-bottom: 20px

    +mediamax(xs)
        padding: 16px 0
        margin: 0

.CloseButton
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)

.Search
    padding: 0 16px 12px

.Footer
    padding: 16px 24px
    background: $background-default
    border: 2px solid $background-accent-transparency
    border-radius: $border-radius-md $border-radius-md 0 0

    button
        width: 100%

