@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.hotel_info
  &__phone
    display: flex
    width: 100%
    &__desc
      @extend %footnote
      color: $color-text-secondary
      margin-bottom: 12px
    & > div
      margin-left: -24px
