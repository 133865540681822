.bgfileico
    &::before, &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 40px
    &::before
        background-color: $background-accent
        border-radius: $border-radius-sm 0 0 $border-radius-sm
    &::after
        background-image: url('../../images/icons/ic_page.svg')
        background-repeat: no-repeat
        background-position: center
        opacity: 0.3
