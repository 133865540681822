@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.InputContainer
    height: 66px
    width: 240px
    gap: 16px

    &.Compact
        width: 176px

.InputCharacter
    position: relative
    @extend %headline
    color: #000
    border: none
    padding: 17px 0
    background: none
    cursor: text

    &:before
        position: absolute
        top: 50%
        left: 0
        transform: translateY(-50%)
        content: ""
        height: 6px
        width: 100%
        border-radius: 4px
        background: $background-accent
        transition: all 150ms

    &:not(:empty)
        &:before
            display: none


.InputCharacterSelected
    outline: none
    &:before
        background: $background-primary



