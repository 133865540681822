@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Wrapper
    +mediamax(xs)
        position: fixed
        bottom: 0
        left: 0
        right: 0
        border: 2px solid $background-accent-transparency
        border-radius: $border-radius-md $border-radius-md 0 0
