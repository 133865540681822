@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-utilites'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.TicketHeader
    +stack(8px, row)
    padding: 24px 12px 20px
    :global(.text-field)
        padding: 0
        flex: 1 1 0
    :global(.text-field-label), :global(.text-field-text)
        text-align: center

.TicketFooter
    +stack(24px)
    padding: 16px 0
    :global(.text-field)
        padding: 16px 24px

    +mediamax(xs)
        gap: 0

.TicketUser
    position: relative
    :global(.text-field-text)
        padding-right: 40px
        +multiline-end-ellipsis(1)

    .Rating
        position: absolute
        right: 24px
        bottom: 10px

.Ticket__mobile
    .TicketHeader
        :global(.text-field-text)
            @extend %caption
