@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.occupancies
  &__wrapper
    background: $background-default
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    padding: 24px 16px
    margin: 24px -16px
  &__title
    @extend %section
    margin: 10px 24px
    &__hint
      @extend %footnote
      color: $color-text-secondary
      margin-top: 8px

.occupancy
  width: 100%
  display: inline-flex
  align-items: start

  &__trash
    margin-top: 24px
    margin-right: 32px

.occupancyTl
  position: relative
  padding: 8px 0
  &__wrapper
    padding: 0 24px
  &__wrapper_flex
    display: flex
    align-items: center
    justify-content: space-between
  &__trash
    margin: 0 0 0 8px

.occupancy_modal
  &__body
    padding: 16px 24px

.childAge
  display: flex

.flex
  display: flex
