@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.container
  display: flex
  flex-direction: row
  gap: 16px
  padding: 0 24px 16px

.text
  @extend %caption
  color: $color-text-secondary
