@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.form
    +stack(16px, column, center)
    margin-bottom: 8px

.subform
    padding: 32px 40px 24px
    width: 592px
    max-width: 100%

    border: 2px solid $background-accent
    border-radius: $border-radius-md


    .title
        @extend %section
        margin-bottom: 8px

    .description
        @extend %footnote
        color: $color-text-secondary

    .add_email
        @extend  %body
        +stack(8px, row, center)
        padding: 14px 0 30px

    .email
        +stack(4px, row, center)

    .reserve_email
        margin-bottom: 16px

    .field
        margin-left: -24px
