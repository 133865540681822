@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"

.discountFramed
  padding: 16px
  margin: 24px 0
  border: 2px solid $background-accent
  border-radius: $border-radius-md

  &__mobile
    padding: 8px 2px
    margin-top: 16px

    [class=checkbox-new]
      padding: 16px
      align-items: center
