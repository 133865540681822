@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.discounts-list
    &__empty
        @extend %section
        text-align: center
        margin-top: 40px
        grid-column: span 2

    &-item
        position: relative
        border: 2px solid $background-accent
        border-radius: $border-radius-md
        padding: 24px
        min-height: 100%
        cursor: pointer
        &__close
            position: absolute
            right: -0.5rem
            top: -0.5rem
            cursor: pointer
        &__header
            display: flex
            justify-content: space-between
            align-items: center
        &__title
            @extend %section
            flex: 1
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            margin: 0 0.25rem 0 0
        &__status
            @extend %caption
            color: $color-text-secondary
            &--rejected
                color: $color-hint-warning

        &__line
            display: flex
            align-items: center
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            padding-top: 8px
            @extend %footnote
            p
                margin: 0 0 0 0.5rem
                color: $color-text-secondary
