@import "@russpass/partner-front-ui/build/assets/styles/common/vars"

.serviceSwitch
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  margin-bottom: 24px
.desc
  color: $color-text-secondary

.switch
  padding: 0
