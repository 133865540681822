@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.hotel-tariff
    &__header
        text-align: center
        padding-top: 48px
    &--title
        @extend %title
        margin-bottom: 4px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    &--name
        @extend %footnote
        margin-bottom: 10px
        color: $color-text-secondary
