@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.wrapper
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 8px
  padding-right: 10px

.item
  @extend %body
  padding: 8px 16px
  border-radius: $border-radius-sm
  border: 2px solid $background-accent

.empty
  @extend %section
  color: $color-text-tertiary
