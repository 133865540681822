@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Tooltip
  position: absolute
  z-index: 101
  top: 60%
  left: 20%
  background:  $background-floating
  width: 312px
  border-radius: $border-radius-md

  +mediamax(lg)
    top: auto
    left: 50%
    position: fixed
    transform: translate(-50%, calc(-50% + 70px))
