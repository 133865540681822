@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Start
    position: absolute
    top: 0
    left: 0

.End
    position: absolute
    top: 0
    right: 0
    +mediamax(lg)
        top: auto
        transform: translateY(-4px)

    &__description
        +mediamax(lg)
            top: auto
            transform: translateY(-12px)
