@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
.wrapper
    position: relative
    display: flex
    a
        opacity: 0.3
        position: relative
    &:hover
        a
            opacity: 1

    &:nth-child(2n)
            &:before
                content: ''
                display: block
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                background: linear-gradient(90deg, $background-default 0%, $background-skeleton 19.79%, $background-skeleton 74.48%, $background-default 100%)
                opacity: 0.2
                z-index: -999
    &:last-child
            &::after
                display: none
    
    &:after
            content: ''
            display: block
            height: 2px
            width: 100%
            background: $background-accent-transparency
            position: absolute
            bottom: 0

    .reportRow
        display: grid
        grid-template-columns: 0.8fr 0.64fr 1.2fr 1.12fr 0.65fr 0.1fr
        grid-column-gap: 8px
        position: relative
        grid-auto-rows: 102px
        align-items: center
        cursor: pointer

        div
            padding: 0
            cursor: pointer
            input
                text-overflow: ellipsis
                white-space: nowrap
                overflow: hidden
                cursor: pointer
            label
                cursor: pointer

