@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Title
    @extend %title
    margin: 0 -8px 16px
    text-align: center

    &__mobile
        @extend %section

.Rating
    +stack(0, row)
    margin: 54px -104px 0

    +mediamax(xs)
        margin: 16px 0 0
        flex-wrap: wrap

    .Item
        flex: 0 0 auto
        @extend %headline
        width: 64px
        height: 64px
        border-radius: 50%
        transition: all 200ms

        &__mobile
            @extend %section

        &.Active
            &.Low
                background: #{$color-hint-warning}15
                color: $color-hint-warning

            &.Normal
                background: #{$color-text-primary}15
                color: $color-text-primary

            &.High
                background: #{$color-hint-success}15
                color: $color-hint-success

