@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.DisputePopupMobile
  @extend %body
  margin: auto
  padding: 20px 24px
  width: 85%
  position: absolute
  top: 75%
  left: 0
  right: 0
  background: $background-floating
  border-radius: $border-radius-md
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05)

  button
    display: flex
    justify-content: center
    align-items: center
    gap: 16px

    span
      color: $color-hint-error
