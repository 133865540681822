@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Filters
    +stack(8px, row)
    :global(.radio-button__label)
        white-space: normal

    &::-webkit-scrollbar
        display: none

    +mediamax(xs)
        overflow: auto
        max-width: 100%