@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.PhoneWrapper
    display: flex
    align-items: center
    justify-content: space-between

    .ButtonWrapper svg
        margin-right: 16px

    &.Compact
        flex-direction: column
        align-items: start
        .ButtonWrapper
            margin-left: 16px
            button
                padding: 8px 12px
                min-height: 40px
