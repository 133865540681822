@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.default_form

    &__form_items
        margin-left: -24px
        margin-bottom: 24px

    &__file_upload
        margin: 16px 24px 40px 24px

        +mediamax(xs)
            margin: 0 16px 40px 16px

    &__agreement
        display: flex
        align-items: center
        padding: 21px 26px 21px 24px
        background: #FFFFFF
        border-radius: $border-radius-md
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04)
        +mediamax(xs)
            flex-direction: column
            row-gap: 16px

        button
            min-width: 248px
            +mediamax(md)
                min-width: auto


    &__agreement_text
        @extend %caption
        color: $color-text-secondary
        margin-right: 56px
        +mediamax(xs)
            margin-right: 0

    &__textarea
        textarea
            cursor: text
