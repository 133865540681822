@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import "@russpass/partner-front-ui/build/assets/styles/mixins/mixin-utilites"

.RadioButton
  list-style: none

  > div
    > div:last-child
      +multiline-end-ellipsis(2)

.AddButton
  display: flex
  align-items: flex-end

  &__text
    @extend %body

.editInput
  padding: 0
  width: 100%
  background: none
  border: none
  &::placeholder
    color: $color-text-tertiary

.divider
  margin-bottom: 16px
