@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.headline
    margin-top: 64px

    &__top
        display: flex
        justify-content: space-between

        &__title
            @extend %headline
            margin: auto 0

        &__details
            display: flex
            margin-top: 16px
            flex-wrap: wrap

            &__itemContainer
                &:not(:last-child) 
                    margin-right: 16px

            &__itemContainerHalfMR
                margin-right: 8px

            &__itemContainer, &__itemContainerHalfMR
                display: flex
                align-items: center
                
                &__item
                    @extend %body
                    line-height: 24px

                    &:not(:last-child)
                        margin-right: 8px


.mockHeader_bottom
    display: flex
    align-items: center

    &__button
        margin-right: 12px

    &__heart
        background-color: $background-btn-disabled
        border-radius: 12px

.rotated
    svg
        transform: rotate(90deg)

.bordered
    border: 1px solid $color-text-primary
    border-radius: 6px
    padding: 0 4px
    
