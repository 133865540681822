@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.offerText
  overflow: hidden
  a
    @extend %paragraph
    color: $color-secondary
    text-decoration: none
  p, li
    @extend %paragraph
    color: $color-text-primary
  strong, h1
    @extend %title
    color: $color-text-primary

  ul:has(li:nth-of-type(1):nth-last-of-type(1))>li::marker
    content: ""

  table
    border: 1px solid $color-text-secondary
    width: 100%
    overflow-x: scroll
    display: block
    th, td
      border: 1px solid $color-text-secondary
      padding: 8px
      min-width: 100px
    strong
      @extend %footnote

