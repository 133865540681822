@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.container
    padding: 16px 24px
    display: flex
    gap: 16px
    flex-direction: column

    .offer
        text-decoration: none

        padding: 12px 24px
        border: 2px solid $color-hint-success
        border-radius: $border-radius-md
        display: flex
        flex-wrap: nowrap
        align-items: center
        justify-content: space-between
        cursor: pointer

    .title
        @extend %body

    .icon
        flex-shrink: 0



