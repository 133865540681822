@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/common/vars"

.date-control
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05)
  border-radius: $border-radius-sm
  padding: 4px
  margin: 0 16px
  display: flex

  &__switch-button
    flex-grow: 1
    min-height: 14px
    padding: 8px
    font-size: 14px

    &__active
      font-size: 16px

    &__not-active
      color: $color-text-tertiary

  &__mobile
    margin: 0 16px 0
    box-shadow: unset
    border-radius: unset

  &__notification // Решение через margin всегда отображает скролл в модальном окне MultiMenu
    height: 8px
