@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Rating
    +stack(4px,column,center,center)
    @extend %caption
    color: $color-text-secondary

    position: absolute
    right: 30px
    top: 40px

    +mediamax(xs)
        position: static
        margin-top: -4px
        margin-bottom: 4px

.ActionButton
    height: 48px