@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.container
  background: $background-default
  border: 2px solid $background-accent
  border-radius: $border-radius-md
  padding: 24px 16px
  margin: 24px -16px 32px

.header
  margin: 10px 24px 30px

.title
  @extend %section

.description
  @extend %footnote
  color: $color-text-secondary
  margin-top: 8px

.row
  @extend %row

.col
  @extend %col-md-6
  @extend %col-sm-12

.phoneList
  padding: 0
  list-style: none
  margin-bottom: 8px

.phoneRow
  display: flex

.phoneRowDivider
  margin-block: 8px
  border-width: 2px
  border-color: $background-accent-transparency
  opacity: 1
  margin-left: 24px
