@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Container
    max-width: 522px

.Form
    margin: 0 -24px

.Item
    padding: 16px 24px

.ActionWrapper
    +mediamax(xs)
        flex-direction: column-reverse

.ActionInfo
    @extend %caption
    color: $color-text-secondary
    flex: 1 1 0
    +stack(0,row,center,center)

    +mediamax(xs)
        text-align: center

.ActionButton
    flex: 1 1 0
