@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.service-type
  padding: 0 108px
  +mediamax(xs)
    padding: 58px

.service-data
  &__notification
    position: absolute
    right: 56px
    top: 56px
    max-width: 392px
    +mediamax(xs)
      max-width: 300px
  &__type
    &-chooser
      margin-top: 28px
