@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.offer-card
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    height: auto
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    padding: 24px
    cursor: pointer

    &__title
        @extend %title
        word-break: break-all
        margin-bottom: 30px

    &__section:nth-child(2)
        margin-bottom: 12px

    &__text
        @extend %footnote
        color: $color-text-secondary

    &__content
        @extend %body
