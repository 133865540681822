@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.List
  text-align: start
  padding: 24px 0 8px

  .Title
    padding: 0 24px
    @extend %section

  .Item
    @extend %body
    padding: 16px 24px
    +stack(4px, row, center, space-between)
    flex-wrap: nowrap
    cursor: pointer

  .Description
    @extend %footnote
    color: $color-text-secondary
    padding: 0 24px
    margin-top: 2px