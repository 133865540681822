@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.report_wrapper
    .report_empty
         @extend %title
         max-width: 400px
         text-align: center
         margin: 190px auto 0
         &__title
             margin-bottom: 4px
         &__hint
             @extend %footnote
             color: $color-text-secondary

    &__filter
      margin-left: -24px
      margin-bottom: 40px

.report_header
    margin-bottom: 36px

.report_content
    &__title
        @extend %title
