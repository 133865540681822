@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-utilites'

$avatar-size: 40px
$gap: 8px
$text-border-radius: 16px

.message
  &__img-wrapper
    position: relative
    width: $avatar-size
    height: $avatar-size
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0

    &::after
      content: ''
      position: absolute
      inset: 0
      border-radius: 50%
      border: 1px solid $background-accent-transparency

    & > img
      width: 100%
      height: 100%
      object-fit: cover

  &__date
    @extend %caption
    color: $color-text-secondary
    text-align: center
    padding: 16px 0

  &__content
    +stack($gap, row)
    padding-right: $avatar-size + $gap

    +mediamax(xs)
      padding-right: 0

  &__text-wrapper
    overflow: hidden

  &__text
    @extend %body
    flex: 0 1 auto
    padding: 10px
    border: 2px solid $background-accent
    border-radius: 0 $text-border-radius $text-border-radius $text-border-radius
    overflow: hidden
    word-break: break-word

  &__time
    @extend %caption
    color: $color-text-secondary
    margin-top: 4px

  & :global(.feedback__attachments)
    padding: 0
    margin-right: 0
    min-width: 320px
    max-width: 320px
    border-radius: $border-radius-sm
    background-color: transparent
    +mediamax(xs)
      min-width: 0
      max-width: 250px

    :global(.feedback__attachment), :global(.feedback__attachment-content)
      border-color: $background-accent

    :global(.feedback__attachment-title)
      text-align: left

    :global(.feedback__attachment-info)
      +oneline-ellipsis
      text-align: left
      text-transform: uppercase

    :global(.button__icon)
      width: 24px
      height: 24px

  &_rtl
    .message__content
      flex-direction: row-reverse
      padding-right: 0
      padding-left: $avatar-size + $gap
      +mediamax(xs)
        padding-left: 0

    .message__text
      +stack($gap)
      text-align: left
      border-radius: $text-border-radius 0 $text-border-radius $text-border-radius

    .message__time
      text-align: right

  &_from-current-user
    .message__text
      background: $background-accent

    & :global(.feedback__attachments)
      & :global(.feedback__attachment), :global(.feedback__attachment-content)
        border-color: $background-default

