@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.listTitle
  color: $color-secondary

.listFootnote
  padding-left: 124px
  padding-right: 124px
  gap: 28px

  +mediamax(md)
    padding-left: 20px
    padding-right: 20px

  ul
    padding: 0 0 0 18px
    margin: 0
