@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/common/vars"

.empty
  &__title
    @extend %section
    margin-bottom: 4px

  &__description
    @extend %footnote
    color: $color-text-secondary

  &__link
    color: $color-hint-success