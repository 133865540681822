@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Block
  @extend %body
  +stack(16px,row, center)
  text-align: start
  flex-wrap: nowrap
  padding: 16px 24px
  cursor: pointer

  svg
    flex-shrink: 0