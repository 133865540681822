@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-utilites'

.Header
    +stack(8px, row, center, center)
    padding: 48px 0 26px
    width: 100%

    +mediamax(xs)
        padding: 4px 60px
        border-top: 1px solid $background-accent-transparency
        border-bottom: 1px solid $background-accent-transparency

.Info
    +stack(4px)
    overflow: hidden
    width: 100%

.Title
    @extend %title
    width: 100%
    text-align: center
    +oneline-ellipsis

    &__mobile
        @extend %section

.Subtitle
    @extend %footnote
    color: $color-text-secondary
    width: 100%
    max-width: 520px
    text-align: center
    +oneline-ellipsis

    &__mobile
        @extend %caption
