@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"


.List
    :global(.footnote)
        color: $color-text-secondary
    :global(.radio-button__label)
        white-space: normal
    & > div:first-of-type
        &, & > div
            min-width: 100%
            overflow: visible
            position: sticky
            top: 96px
            background: $background-default
            z-index: 1
    &__filtersHidden
        div > div > div
            justify-content: end
        [class*=FiltersContainer_Filters]
            display: none

    +mediamax(md)
        width: 90%

    +mediamax(xs)
        width: auto
        margin-left: -4px
        margin-right: -4px

.FrameMobile
    border: 2px solid $background-primary

    &:last-of-type
        border: 2px solid $background-primary
