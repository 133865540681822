@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.subtitle-wrappper
    margin: 0 168px

.contrast-text
    color: $color-text-primary

.text-field__textCursor
    cursor: text
    textarea
        cursor: text

