@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Header
    border-bottom: none

.Container
    max-width: 522px
    +mediamax(xs)
        margin-top: 8px

.Form
    margin: 0 -24px

    .Reason
        padding: 16px 24px
