@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.info
    padding: 48px
    // background: $background-floating
    border-radius: $border-radius-lg
    border: 2px solid $background-accent

    +mediamax(md)
        padding: 32px 24px 24px 24px
        border-radius: 24px

    +mediamax(xs)
        padding: 24px
        border-radius: 24px

    &__contacts
        margin-top: 12px
        +mediamax(xs)
            margin-top: 20px

        &_title
            @extend %title

