@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Controls
    +stack(8px,row, center, space-between)
    width: 100%
    :global(.switch)
        padding: 0

    &__mobile
        flex-direction: column
        align-items: start
        overflow-x: auto
        gap: 16px

        div:last-of-type
          align-self: start
