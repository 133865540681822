@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.wrapper
    +stack(53px)
    min-height: 100%
    width: 798px
    max-width: 100%
    margin: 0 auto

.list
    +stack(24px)
    flex: 1 1 auto
