@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.userStatus
    display: flex
    align-items: center
    padding: 20px 24px
    border: 2px solid $color-text-secondary
    border-radius: $border-radius-md
    @extend %body
    margin: 0 24px 40px
    svg
        min-width: 40px
    div
        margin-left: 24px
