@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.item
  position: relative
  margin-top: 8px
  padding-top: 8px

  +mediamax(xs)
    margin: 0
    padding: 0

  &:not(:first-child):after
    content: ''
    position: absolute
    top: 0
    left: 24px
    width: calc(100% - 48px)
    height: 2px
    background: $background-accent-transparency

  &__trash
    position: absolute
    top: 36px
    right: 24px
    cursor: pointer

  & [class^=multi_multi_menu__input]
    max-width: 234px
    white-space: normal

.dates
  padding-right: 32px
  div
    white-space: wrap