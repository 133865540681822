@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.wrapper
  @extend %section
  display: flex
  align-items: center
  gap: 8px
  border: 2px solid $color-text-secondary
  padding: 16px
  border-radius: 20px

  .text
    color: $color-text-primary
    margin: 0
