@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.wrapper
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 8px

.item
  @extend %body
  position: relative
  border-radius: $border-radius-sm
  border: 2px solid $background-accent
  padding: 8px 16px

  &:hover &__delete
    display: block

  &__delete
    display: block
    position: absolute
    top: -5px
    right: -5px

    @media (hover: hover)
      display: none
