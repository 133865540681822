@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.container
    margin-bottom: 40px
    max-width: 808px
    width: auto

.description 
    @extend %footnote
    line-height: 24px
    margin-bottom: 8px
    overflow: hidden
    max-height: calc(24px * 4) // высота строки * на кол-во всегда отображаемых строк

.showMore
    display: flex
    align-items: center
    margin: 8px 0
    pointer-events: none

    &__text
        @extend %caption
        line-height: 22px
        color: $color-system-link

    &__icon
        transform: rotate(90deg)
