@import "node_modules/@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "node_modules/@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.place-timetable
  &__row
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 8px
    grid-row-gap: 8px
    width: 100%
    margin: auto
    grid-auto-rows: auto
    padding: 0 24px

    .input--form, .text-field
      padding: 16px 0

      +mediamax(xs)
        padding: 16px

    +mediamax(xs)
      padding: 0 8px

    &__splitted
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px

  &__working_mode_row
    padding-right: 24px
    +mediamax(xs)
      padding: 0px 24px 0 8px

.weekends
  padding: 0 24px
