@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

.contacting_support__status
    position: relative
    padding: 48px
    & [class*=status-request__info]
        margin-top: 96px
    +mediamax(lg)
        & [class*=status-request__action]
            position: relative
    +mediamax(md)
        & [class*=status-request__action]
            position: fixed
            justify-content: center
            bottom: 0
            padding: 16px 24px
            width: 100%
            [class*=status-request__button]
                width: 100%

.contacting_support
    padding: 48px
    border-radius: $border-radius-lg
    border: 2px solid $background-accent

    +mediamax(md)
        padding: 32px 24px 24px 24px
        border-radius: 24px

    &__title
        @extend %title
        margin-bottom: 20px

        +mediamax(xs)
            margin-bottom: 12px