@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/common/vars'

.ticket_item
  width: 100%
  max-width: 392px
  margin: 0 auto 16px
  &:last-of-type
    margin-bottom: 48px

  &__birthday
    position: relative

  &__isChild
    position: absolute
    right: 10px
    top: 0
    bottom: 0
    margin: auto
    height: max-content

  &__wrap
    border-radius: $border-radius-md
    border: 2px solid $background-accent

  &__header
    border-bottom: 2px dashed $background-accent-transparency
    padding: 16px 12px 20px
    text-align: center
    @extend %caption
    position: relative
    &::after, &::before
      content: ""
      background-image: url("../../../../assets/images/icons/ellipse.svg")
      background-size: 8px 16px
      background-repeat: no-repeat
      background-position: center
      width: 8px
      height: 16px
      display: block
      position: absolute
      bottom: -8px
      border-right: 1px solid $background-default
    &::after
      right: -3px
    &::before
      left: -3px
      transform: rotate(-180deg)

    &_label
      color: $color-text-secondary
      margin-bottom: 2px
    &_value
      color: $color-text-primary

  &__content
    padding: 24px 0

  &__row
    @extend %row

  &__column
    @extend %col-md-6
    @extend %col-sm-12