@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.tariff_openclose
  &__container
    display: flex
    flex-direction: column
    margin: auto
    gap: 16px
    max-width: 798px
    margin-bottom: 40px

  &__text
    @extend %body
    color: $color-text-secondary
    margin-bottom: 16px

  &__inner
    border: 2px solid $background-accent
    box-sizing: border-box
    border-radius: $border-radius-md
    padding: 24px 32px

  &__fields
    display: flex
    grid-gap: 111px

    &>div
      width: 328px
      padding: 16px 0 8px 0

.service_tariff_item
  $root: &
  border: 2px solid $background-accent
  box-sizing: border-box
  border-radius: $border-radius-md
  padding: 24px
  display: flex
  height: 100%
  cursor: pointer
  position: relative
  justify-content: space-between

  &__delete
    position: absolute
    top: -8px
    right: -8px
    display: none

  &__copy
    position: absolute
    top: -8px
    right: 36px
    display: none

  &__basic
    position: absolute
    top: -9px
    left: 20px
    display: flex
    align-items: center
    justify-content: center
    width: 26px
    height: 26px

  &:hover
    #{$root}__delete, #{$root}__copy
      display: block

  &__column
    &__left_side
      flex-grow: 1
      padding-right: 8px
      width: calc(100% - 96px)

  &__title
    @extend %body
    margin-bottom: 8px

  &__title, &__days, &__price, &__time
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__days, &__price, &__time
    @extend %footnote
    color: $color-text-secondary

  &__price
    margin-bottom: 8px

.basic
  &__title
    display: flex
    align-items: center
    margin-bottom: 2px

  &__desc
    @extend %footnote
    color: $color-text-secondary

  &__img
    width: 206px
    height: 234px

  .checkbox-new
    align-items: center

.hint_basic
  @extend %caption
  color: $color-text-secondary

.hint_child
  @extend %caption
  color: $color-text-primary
