@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.card
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    height: 100%
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    position: relative
    display: flex
    align-items: center
    text-align: left
    cursor: pointer
    padding: 24px
    &__content
        width: 100%
    &__title
        @extend %section
        margin-bottom: 8px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    &__desc
        @extend %footnote
        color: $color-text-secondary
    &__delete_button
        position: absolute
        z-index: 1
        right: -6px
        top: -6px

.tag
    @extend %footnote
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    position: relative
    padding-left: 24px
    &.included
        color: $color-hint-success

        &:before
            content: ""
            position: absolute
            margin-top: auto
            margin-bottom: auto
            top: 0
            bottom: 0
            left: 0
            margin-right: 10px
            width: 14px
            height: 14px
            border-radius: 50%
            background-color: rgba(0, 116, 112, 0.1)
            background-image: url("../../../../../../../../assets/images/icons/included.svg")
            background-repeat: no-repeat
            background-position: center


    &.payment
        color: $color-hint-warning
        &:before
            content: ""
            position: absolute
            margin-top: auto
            margin-bottom: auto
            top: 0
            bottom: 0
            left: 0
            margin-right: 10px
            width: 14px
            height: 14px
            border-radius: 50%
            background-color: rgba(231, 109, 0, 0.1)
            background-image: url("../../../../../../../../assets/images/icons/payment.svg")
            background-repeat: no-repeat
            background-position: center
    &.notincluded
        color: $color-text-secondary
        &:before
            content: ""
            position: absolute
            margin-top: auto
            margin-bottom: auto
            top: 0
            bottom: 0
            left: 0
            margin-right: 10px
            width: 14px
            height: 14px
            border-radius: 50%
            background-color: rgba(29, 29, 29, 0.1)
            background-image: url("../../../../../../../../assets/images/icons/notincluded.svg")
            background-repeat: no-repeat
            background-position: center
