@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.container
    display: flex
    flex-direction: column
    row-gap: 24px

.section
    &__title
        display: flex
        flex-direction: row
        gap: 12px
        margin-bottom: 8px
        
        &__text
            @extend %section

    &__list
        display: flex
        flex-direction: column
        row-gap: 4px

        &__item
            display: flex
            align-items: center

            &__point
                background-color: #D9D9D9
                width: 8px
                height: 8px
                border-radius: 50%

            &__text
                @extend %footnote
                margin-left: 8px

            &__desc
                margin-top: 8px
                color: $color-natural-3
                font-size: 14px
