.loaderWrap 
    height: 100%
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.statusRequestCardWrap
    height: 100%
    margin: 0 178px
    position: relative