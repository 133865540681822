@import "@russpass/partner-front-ui/build/assets/styles/generic/grid.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"

*
  box-sizing: border-box

body
  background: $background-default
  padding: 0
  margin: 0
  box-sizing: border-box
  overflow-y: scroll
  overflow-x: hidden
  &.fixed-popup
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    overflow: hidden
    +mediamax(xs)
      position: fixed

.container
  @extend %container

  +mediamax(xs)
    width: auto
    margin: 0 24px
    padding: 0

input
  text-overflow: ellipsis
  overflow-x: hidden

.mb-48
  margin-bottom: 48px

.mb-40
  margin-bottom: 40px

.mb-24
  margin-bottom: 24px

.mb-32
  margin-bottom: 32px

.mb-16
  margin-bottom: 16px

.mb-12
  margin-bottom: 12px

.mb-4
  margin-bottom: 4px

.mb-8
  margin-bottom: 8px

.mt-8
  margin-top: 8px

.mt-16
  margin-top: 16px

.mt-24
  margin-top: 24px

.mt-32
  margin-top: 32px

.ml-8
  margin-left: 8px

.ml-16
  margin-left: 16px

.ml-24
  margin-left: 24px

.px-16
  padding-left: 16px
  padding-right: 16px

.px-24
  padding-left: 24px
  padding-right: 24px

.pb-32
  padding-bottom: 32px

.content-wrapper
  max-width: 450px
  margin: 0 auto 40px

  .warning
    color: $color-hint-warning

  +mediamax(xs)
    max-width: none
    width: auto
    margin: 0

  &--large
    max-width: 798px

  &__form
    margin: 0 -24px

    +mediamax(xs)
      margin: 0px -16px

  &--center
    text-align: center

  &__framed
    margin: 24px -16px
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    padding: 32px 40px

    +mediamax(sm)
      margin: 16px 0
      padding: 28px 16px 14px 16px

.input--form
  +mediamax(xs)
    padding: 16px

.action-button-sticky
  bottom: 0
  z-index: 10
  position: sticky

.position-relative
  position: relative

.full-width
  width: 100%

.link
  cursor: pointer
  color: $color-secondary
  text-decoration: none

.link-secondary
  cursor: pointer
  color: $color-default
  text-decoration: underline

.form-item
  padding: 16px 24px
