@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.switch_wrapper
    padding: 0 24px
    margin-right: -88px
    +mediamax(xs)
        padding: 16px 16px 0
    +mediamax(md)
        margin-right: 0
        padding-bottom: 8px

    &_hint
        margin-top: 2px
        @extend %caption
        color: $color-text-secondary

.link
    color: $color-primary
