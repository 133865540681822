
@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.tariffCards__container
    display: grid
    max-width: 798px
    margin: auto
    grid-auto-rows: auto
    grid-template-columns: repeat(2, calc( (100% - 48px) / 2 ))
    column-gap: 48px
    row-gap: 48px
    grid-auto-rows: 1fr

    +mediamax(xs)
        grid-template-columns: repeat(1, 100%)

.title
    @extend %title
