@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.supportLink
  @extend %footnote
  padding: 36px 24px 0
  display: flex
  align-items: center

  &__icon
    margin-right: 12px

  &__action
    color: $color-secondary
    text-decoration: none
    margin-left: 5px

  &__divider
    margin-top: 16px
