@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.Card
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    padding: 16px 0 24px

    .Title
        padding: 20px 24px 12px
        @extend %section

    :global(.text-field)
        padding: 16px 24px
