@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.Ticket
    color: red
    border-radius: $border-radius-md
    border: 2px solid $background-accent
    background: $background-default

    .Divider
        position: relative
        border-bottom: 2px dashed $background-accent
        height: 2px

        &:before, &:after
            height: 16px
            width: 8px
            display: block
            content: ""
            position: absolute
            background: $background-default
            border-radius: $border-radius-xs
            border: 2px solid $background-accent

        &:before
            left: 0
            transform: translate(-2px, -50%)
            border-top-left-radius: 0
            border-bottom-left-radius: 0
            border-left-width: 0

        &:after
            right: 0
            transform: translate(2px, -50%)
            border-top-right-radius: 0
            border-bottom-right-radius: 0
            border-right-width: 0
