@import "@russpass/partner-front-ui/build/assets/styles/common/vars"

.list
    margin-bottom: 32px
    &__row
        display: grid
        grid-template-columns: 55px 3fr 2fr 3fr 24px
        gap: 62px
        cursor: pointer
        padding-top: 16px
        padding-bottom: 16px
        border-bottom: 2px solid $background-accent-transparency
        &--marked
            position: relative
            overflow: visible
            &:before
                content: ''
                width: 12px
                height: 12px
                background: $color-primary
                display: block
                border-radius: 50%
                position: absolute
                left: -28px
                top: 59px
        &:last-of-type
            border-bottom: 0
    &__col
        display: flex
        flex-direction: column
        justify-content: center
        padding-top: 16px
        padding-bottom: 16px
        overflow: hidden

    &__label
        @extend %caption
        white-space: nowrap
        color: $color-text-tertiary
        margin-bottom: 4px

        .warning
            color: $color-hint-warning

    &__value
        @extend %section
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

    &__panel
        display: flex
        justify-content: flex-end
        margin-bottom: 32px

    &__total
        @extend %caption
        color: $color-text-secondary
