@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.Status
    @extend %footnote
    color: $color-secondary
    display: flex
    align-items: center
    margin-top: -4px

    &.Warning
        color: $color-hint-warning

    & > div
        display: flex
        align-items: center
