@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import '~react-day-picker/lib/style.css'

$week-gap: 16px

.datepicker
  position: relative
  display: flex
  justify-content: center

  &__hint
    @extend %footnote
    position: relative
    left: 24px
    top: -12px
    width: calc(100% - 48px)
    display: block
    color: $color-text-secondary

    &--error
      color: $color-hint-error

    &--success
      color: $color-hint-success

  &--no-wrap,  &--day
    max-width: 600px

    .datepicker__calendar
      .DayPicker-NavButton
        &--prev
          left: 0 !important

        &--next
          right: 0 !important

      .DayPicker-Months
        padding: 0 !important

  &--day
    max-width: 600px
    .datepicker__calendar
      &.DayPicker
        .DayPicker-Day
          &--selected
            .datepicker-day
              background-color: transparent !important
              position: relative

              &::after
                content: ''
                display: block
                width: 46px
                height: 36px
                position: absolute
                top: 50%
                left: 50%
                background-repeat: no-repeat
                z-index: 1
                background-image: url('../../../../assets/images/datepicker/circle.png')
                transform: translate(-50%, -50%)

            &.DayPicker-Day[aria-disabled=false] + .DayPicker-Day[aria-disabled=false]
              .datepicker-day
                &:before
                  content: none !important

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover, .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover
    background: none

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover
    background: none

  .DayPickerInput
    width: 100%

  .DayPickerInput-Overlay
    top: 12px
    z-index: 10
    border-radius: $border-radius-md
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.04)
    background-color: $background-floating
    min-width: auto

  &--mobile .DayPickerInput-Overlay
    position: fixed
    top: 48px
    left: 0
    width: 100vw
    height: calc(100vh - 118px)
    background: $background-default
    z-index: 1001
    border-radius: initial
    padding-bottom: 12px

  .datepicker__calendar
    .DayPicker-NavBar
      position: absolute
      top: 0
      right: 0
      left: 0
      height: 88px
      padding-bottom: 120px

    .DayPicker-NavButton--interactionDisabled
      display: block !important
      opacity: 0.3
      cursor: auto
      &:hover
        opacity: 0.3

    .DayPicker-NavButton
      top: 20px
      width: 24px
      height: 24px
      margin: 0
      transform: none
      background-size: 100%

      &--prev
        left: 24px
        background-image: url("../../../../assets/images/icons/ic_chevron_left.svg")

      &--next
        right: 24px
        background-image: url("../../../../assets/images/icons/ic_chevron_right.svg")

    .DayPicker-Caption
      height: 62px
      margin: 0
      padding: 0
      text-align: center

      > div
        display: flex
        align-items: center
        justify-content: center
        height: inherit
        text-transform: inherit
        @extend %body

    .DayPicker-Weekdays
      display: block
      margin-top: 0

    .DayPicker-WeekdaysRow
      display: grid
      grid-template-columns: repeat(7, 1fr)
      grid-gap: 24px

    .DayPicker-Weekday
      padding: 0
      text-transform: inherit
      letter-spacing: normal
      color: $color-text-secondary
      @extend %caption

    .DayPicker-Months
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 70px
      padding: 0 26px

    .DayPicker-Body
      display: flex
      flex-direction: column
      margin-top: 10px

    .DayPicker-wrapper
      padding-bottom: 0

    .DayPicker-Month
      width: 100%
      margin: 0
      padding: 0

    .DayPicker-Week
      display: grid
      grid-template-columns: repeat(7, 24px)
      grid-gap: $week-gap

    .DayPicker-Day
      padding: 0
      color: $color-text-primary
      background-color: transparent
      height: 42px
      @extend %body

      &--today
        position: relative
        font-weight: 500
        &:not(.DayPicker-Day--hideActive):not(.DayPicker-Day--outside)::after
          content: ''
          display: block
          width: 6px
          height: 6px
          position: absolute
          bottom: 3px
          left: 50%
          z-index: 1
          background-color: $background-primary
          border-radius: 50%
          transform: translateX(-50%)

      &--outside
        &::after
          display: none

      &--disabled
        opacity: 1
        pointer-events: none
        color: $color-text-tertiary !important
        @extend %body

    &.DayPicker

      .datepicker-day__inner
        color: inherit
        background-color: transparent
        &:hover
          color: inherit
          background-color: transparent

      .DayPicker-Day
        &--selected
          color: $color-text-primary

          .datepicker-day
            background-color: transparent

            &::after
              content: ''
              display: block
              width: 46px
              height: 36px
              position: absolute
              top: 50%
              left: 50%
              background-repeat: no-repeat
              z-index: 1
              background-image: url('../../../../assets/images/datepicker/circle.png')
              transform: translate(-50%, -50%)

          &:not(.DayPicker-Day--from):not(.DayPicker-Day--to)
            .datepicker-day
              &::after
                width: calc(100% + (2 * #{$week-gap}))
                margin: 0

    .datepicker-day
      position: relative
      display: flex
      justify-content: center
      align-items: center
      height: 24px
      margin: 10px 0

      &__inner
        position: absolute
        z-index: 1
        display: flex
        align-items: center
        justify-content: center
        height: 22px
        width: 24px
        border-radius: 50%


  .DayPicker__calendar-one
    .DayPicker-Months
      grid-template-columns: none
      grid-gap: 0

    &--mobile
      width: 100%

      .DayPicker-NavButton--prev
        left: 32px
      .DayPicker-NavButton--next
        right: 32px

      .DayPicker-WeekdaysRow
        grid-gap: 16px

      .DayPicker-Months
        grid-template-columns: 1fr
        padding: 0 28px

      .DayPicker-Body
        margin-top: 12px

      .DayPicker-Week
        grid-template-columns: repeat(7, 1fr)

      .DayPicker-Day
        margin: 2px 0

  &--mobile
   .datepicker__calendar
    .DayPicker-NavBar
      display: none

    .DayPicker-Caption
      height: 40px
      margin: 16px 0

      > div
        justify-content: flex-start

    .DayPicker-Months
      grid-template-columns: 1fr
      grid-gap: 0

    .DayPicker-Body
      margin-bottom: 8px

    .DayPicker-Week
      grid-template-columns: repeat(7, 1fr)

    &.DayPicker
      overflow: auto

  &.DayPicker--reverse.datepicker__calendar, &.DayPicker--reverse .DayPicker
    .DayPicker-Months
      .DayPicker-Day
        &--selected
          &.DayPicker-Day--start + .DayPicker-Day .datepicker-day:before
            visibility: hidden
          &.DayPicker-Day--end + .DayPicker-Day .datepicker-day:before
            visibility: visible

  &__footer
    margin-bottom: 8px
    .datepicker__button
      color: $color-secondary
      padding: 16px 24px

  &__header
    display: none

  &--mobile &__header-position
    display: none

  &__title
    @extend %section
    text-align: center
    margin: 0
    display: inline-block

  &__button-close
    position: absolute
    right: 12px

  &__footer-mobile
    display: none

  &--mobile
    &.DayPickerShow
      .datepicker__header-wrap

        display: flex
        align-items: center
        justify-content: center
        position: relative
        height: 48px
        box-shadow: 0 0.5px 0 #F1E5D4

      .datepicker__header-position

        display: block
        position: fixed
        top: 0
        left: 0
        width: 100vw
        z-index: 1002
        background-color: $background-default

      .datepicker__footer-mobile

        position: fixed
        bottom: 0
        left: 0
        width: 100vw
        z-index: 1002
        background-color: $background-default
        border-top: 2px solid rgba(245, 223, 184, 0.5)
        border-radius: 16px 16px 0 0
        padding: 16px 24px
        display: grid
        grid-gap: 12px
        grid-template-columns: 1fr 1fr
