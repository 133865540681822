@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.container
    position: relative
    height: 600px
    width: 100%
    border: 1px solid $color-natural-5
    border-radius: 20px
    margin-bottom: 40px

    :global(ymaps)
        border-radius: 20px

.location
    position: absolute
    z-index: 1
    top: 24px
    left: 24px
    box-shadow: 0px 12px 16px 0px #0000000A
    background: $background-floating
    padding: 16px
    border-radius: 16px

    &__title
        @extend %title
        margin-bottom: 8px

    &__address
        @extend %footnote
        color: $color-text-secondary
