@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.ratings
    height: 100%
    display: flex
    flex-direction: column
    .ratings-marks
        flex: 1
        display: flex
        flex-direction: column
        .list
            flex: 1
    &--no-objects
        @extend %section
        margin: 190px 0 48px 0
        text-align: center

.ratings-info
    margin-bottom: 48px

    &__badges
        display: flex
        flex-wrap: wrap
        margin: 0
        gap: 8px 12px

    &__badge
        @extend %body
        border: 2px solid $background-accent
        border-radius: $border-radius-sm
        padding: 6px 16px
        position: relative

    &__mark
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        height: 40px
        padding: 4px 6px
        @extend %caption
        text-align: center
        background: $background-secondary
        border-radius: 8px
        color: $color-default

        &__colored--low
            background: $color-hint-error
        &__colored--medium
          background: $background-secondary

.ratings-marks
    &__header
        display: flex
        align-items: center
        margin-bottom: 16px

        &--restaurant
            margin-bottom: 0

        > div:nth-child(2)
            width: auto
            background: $background-accent-transparency
            border-radius: $border-radius-sm
            padding: 12px 16px

            > div
                > div
                    @extend %body
                    > div
                        color: inherit

        .list__total
            margin-left: auto

    &__list
        overflow-x: auto

    &__list-row
        grid-template-columns: minmax(max-content, 154px) minmax(320px, 2fr) minmax(max-content, 1fr) minmax(min-content, 60px)
        min-width: fit-content
        gap: 36px
        +mediamax(md)
            padding-block: 8px
            gap: 6px

.modal-rating-header
    width: 100%
    &__title
        @extend %title
        display: flex
        justify-content: center
        padding: 48px 0 48px 36px
        text-align: center
        gap: 12px
        +mediamax(xs)
            padding: 16px 8px

    .mark
        @extend %caption
        border-radius: $border-radius-sm
        padding: 4px 6px
        display: flex
        justify-content: center
        align-items: center
        vertical-align: top
        background: $color-text-secondary
        color: $color-default
        min-width: 40px
        height: 40px
        margin-top: -2px
        text-align: center

        &__colored--low
            background: $color-hint-error
        &__colored--medium
            background: $color-text-secondary
        &__colored--high
            background: $background-secondary

    .button--third
        position: fixed
        right: 40px
        top: 40px
        color: $color-hint-error
        +mediamax(xs)
            right: 40px
            top: 80px

.ratings-card
    &__container
        max-width: 392px !important
        width: 100%
        padding: 0 0 24px 0 !important

    &__label
        @extend %caption
        color: $color-text-tertiary
        margin-bottom: 8px
        padding-top: 16px
        margin-top: 0
    &__value
        white-space: nowrap
        text-overflow: ellipsis
        @extend %section
        padding-bottom: 16px
        display: flex
        svg
            margin-left: 32px
            color: $color-text-tertiary

        &-text
            overflow: hidden
            text-overflow: ellipsis

    &__bonuses
        margin-top: 42px

        button
            min-width: 212px
        span
            @extend %section
            color: $color-secondary !important
            display: flex
            align-items: center

    &__block
        @extend %section
        border: 2px solid $background-accent
        border-radius: $border-radius-md
        padding: 16px 16px
        max-width: 392px
        &-item
            display: flex
            gap: 8px
            margin-bottom: 24px
            word-break: break-word
            svg
                flex-shrink: 0
                width: 24px
                height: 24px
                &.secondary
                    color: $color-secondary
                &.warning
                    color: $color-hint-warning

        .ratings-card__bonuses
            margin-top: 0
    &__tickets
        gap: 0 !important

    form
        display: flex
        flex-direction: column
        justify-content: space-between

        .content-wrapper__form
            margin: 0

    .form
        &__row
            @extend %row
            grid-template-columns: auto 1fr
            grid-row-gap: 0

        &__column
            @extend %col-md-6
            grid-column-end: auto
            min-width: 124px
