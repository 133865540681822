@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/common/vars'

.content 
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    max-width: 460px
    margin: 0 auto
    text-align: center
    
    &__icon
        margin-bottom: 22px

    &__title
        @extend %section 
        margin-bottom: 18px
    
    &__desc
        @extend %footnote 
    
        max-width: 360px
        margin-bottom: 40px
        color: $color-text-secondary

    &__link 
        @extend %body
        text-decoration: none

    &__btns
        display: flex
        gap: 12px
