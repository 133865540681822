@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.message
  border: 2px solid $color-text-secondary
  border-radius: $border-radius-md
  padding: 16px
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 36px
  &__text
    @extend %body
    margin-left: 8px
    width: calc(100% - 24px - 8px)
  &__link
    color: $color-hint-success
    text-decoration: none

