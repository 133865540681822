@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.container
  display: flex
  align-items: flex-start
  overflow: hidden
  width: 100%

.inner
  width: 100%
  max-height: 100%
  overflow: auto
  position: relative
  display: flex
  flex-direction: column-reverse

.loader
  position: absolute
  inset: 0
  display: grid
  place-items: center

.messages
  +stack(12px)

  width: 100%
  max-width: 450px
  margin: 0 auto 40px

  :global(.infinite-scroll-component)
    +stack(12px)

  +mediamax(xs)
    padding: 0 24px

  &.loading
    opacity: 0.5