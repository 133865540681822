@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.description
  @extend %body
  color: $color-text-secondary
  margin-bottom: 18px

.error
  @extend %footnote
  color: $color-hint-error

.fields
  :global(.select-simple__label)
    left: 0
  :global(.select-simple__control)
    padding-left: 0
  :global(.select-simple__hint)
    left: 0

.addButton
  padding: 0

  &:active, &:hover
    background-image: unset !important

.field
  position: relative

  &:not(:first-child)
    border-top: 2px solid $background-accent-transparency

  & > div
    padding-left: 0
    padding-right: 10px

  .input
    position: relative

  .trash
    position: absolute
    top: 50%
    right: 0
    transform: translateY(-50%)
    cursor: pointer
