@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.wrapper
  max-width: 798px
  margin: 0 auto

.container
  display: grid
  grid-template-rows: 1fr 1fr

  +mediamax(xs)
    margin: 0 -16px
    display: block

  +mediamin(md)
    grid-template-rows: 1fr
    grid-template-columns: 1fr 353px

.rates > :global(.input__hint--error)
  margin-left: 16px




