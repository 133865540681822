@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.wrapper
  margin-bottom: 24px

.actions
  margin: -16px -24px
  cursor: pointer

.excursionProgram__desc
  margin: 0 -24px

.containerPhotos
  margin: 0 auto 48px
  min-width: 450px
  max-width: min-content

  +mediamax(xs)
    min-width: fit-content

.photoDescText
  padding: 16px 0

  textarea
    @extend %footnote
