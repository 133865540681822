@import '@russpass/partner-front-ui/build/assets/styles/common/vars'
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-utilites'

.Title
    position: relative
    padding: 0 42px

    .Placeholder
        width: 100%
        +oneline-ellipsis

.TimeUntilWrapper
    position: absolute
    top: -4px
    right: 0

.TimeUntil
    cursor: pointer
    padding: 4px 8px
    border-radius: $border-radius-xs
    background: $background-accent
    @extend %caption
    transform: translateY(4px)

.ChatButtonWrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin-top: 16px
    button
        width: 100%
        max-width: 292px

.Header__mobile
    .Title
        padding: 0

    .TimeUntilWrapper
        position: fixed
        top: 10px
        right: 12px

    .TimeUntil
        @extend %footnote
        padding: 6px 4px

