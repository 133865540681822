@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.frame-mobile
    +mediamax(xs)
        border: 2px solid $background-primary
    &:last-of-type
        +mediamax(xs)
          border-bottom: 2px solid $background-primary
