@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
    
.moderatorNotes
    padding: 20px 24px
    background: $background-default
    border: 2px solid $color-hint-warning
    border-radius: $border-radius-md

    .moderatorNotesText
        @extend %footnote
        word-wrap: break-word
        margin: 0