@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import "src/assets/styles/generic/bg"

$dialogues-attachment-border-width: 2px
$dialogues-attachment-preview-size: 82px

.dialogues

  &__filters
    display: flex
    align-items: center
    flex-grow: 1

    > div
      width: auto
      padding: 0

    .button
      margin-right: 12px

    .dialogues__loader-wrapper
      padding: 24px

  &__loader-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding: 100px 0
    position: absolute
    top: 0
    left: 0
    bottom: 0

  .modal-window-right__header
    border: 1px solid $background-accent
    justify-content: center
    +mediamax(xs)
      margin-bottom: 0

  &__modal-header
    padding: 48px 0 6px
    text-align: center
    +mediamax(xs)
      width: 100%
      padding: 4px 48px

  &__modal-title
    @extend %title
    text-decoration: none
    width: 100%
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &__mobile
      @extend %section

  &__modal-subheader
    @extend %footnote
    margin-top: 4px
    color: $color-text-secondary

    &__mobile
      @extend %caption
      margin-top: 0


  &.modal-window-right
    .modal-window-right__wrap
      .modal-window-right__content
        display: flex
        flex-direction: column

  &__row__unread
    +mediamax(xs)
      border: 2px solid $background-primary

      &:last-of-type
        border: 2px solid $background-primary

.message-attachment
  @extend %caption
  border: 2px solid $background-accent
  border-radius: $border-radius-sm
  width: 180px
  min-width: 180px
  padding: 9px 9px 9px 49px
  position: relative