@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.hotel-info
  &__row
    @extend %row

  &__col
    @extend %col-md-6
    @extend %col-sm-12

  &__upload_title
    display: inline-flex
    align-items: center

    //TODO перейти на стили modal-window-right
  &__header
    text-align: center
    padding-top: 48px
    &--title
      @extend %title
      margin-bottom: 4px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &--name
      @extend %footnote
      margin-bottom: 10px
      color: $color-text-secondary
