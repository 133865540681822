@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.actions-wrapper-container
    position: fixed
    width: 100%
    margin: auto calc((310px / 2))
    z-index: 1
    bottom: 16px
    right: 0
    left: 0

    +mediamax(lg)
        margin: auto

.actions-wrapper
    max-width: 600px
    padding: 16px 24px
    margin: auto
    background: $background-floating
    border-radius: $border-radius-md
