@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.Container
    +stack(16px)
    margin: 0 auto
    padding: 0 24px 30px
    width: 100%
    max-width: 522px

    +mediamax(xs)
        margin-top: 26px
