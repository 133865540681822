@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.SubmitButton
    display: flex
    justify-content: center
    background: $background-default
    z-index: 1
    button
        width: 100%
        max-width: 450px

    +mediamax(xs)
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        border-radius: $border-radius-md $border-radius-md 0 0
        border: 2px solid $background-accent-transparency

.Placeholder
    height: 84px


.Wrapper
    position: sticky
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    padding: 16px 0
    z-index: 1
.Floating
    width: 100%
    max-width: 498px
    background: $background-floating
    box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.04)
    border-radius: $border-radius-md
    padding: 16px 24px
