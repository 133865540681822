@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.actionFormWrap
  max-width: 648px
  width: 100%
  background-color: $background-floating
  border-radius: $border-radius-md
  display: grid
  grid-gap: 24px
  padding: 24px
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
  align-items: center
  position: sticky
  bottom: 16px
  margin: auto