@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.orders_filters
  display: inline-flex
  gap: 12px
  width: 100%
  +mediamax(xs)
    max-width: calc(100vw - 48px)
    overflow-x: auto
    &::-webkit-scrollbar
      display: none
  &__kind
    margin: -16px -24px
    display: inline-flex
    +mediamax(md)
      margin: -16px
  > div
    padding: 0
    width: auto

.search_input
  width: 100%
  max-width: 196px
  position: relative
  :global(.input__custom-icon)
    pointer-events: none
  :global(.input__icon)
    position: absolute
    right: 0
    bottom: 14px
    top: 14px
  > :global(.input)
      box-shadow: none
      &:not(:global(.input--single-focus))
        border: 2px solid $background-btn-accent
      &:global(.input--single-focus)
        :global(.input__input),
        :global(.input__icon)
          transform: none

.date_filter
  + div
    right: 50%
    transform: translateX(50%)
    :global(.date-control)
      width: fit-content
      margin: 0 auto
      :global(.date-control__switch-button)
        padding: 12px
        font-size: 14px
        line-height: 18px
