@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

$completed-color: mix($background-secondary, $background-default, 10%)
$active-color: mix($background-secondary, $background-default, 30%)
$blocked-color: mix($background-btn-black, $background-default, 30%)

$border-width: 2px

.Calendar
  margin-top: 32px
  width: 100%

  &__mobile
    div[class=DayPicker-Weekday]
      @extend %caption

    div[class*=DayPicker-Day]
      padding-top: 42px
      border: none
      width: unset
      height: 32px

      .Day
        margin: 4px
        inset: 0
        border: none
        border-radius: $border-radius-xs

        .DayNumber
          @extend %body
          color: inherit
          left: 50%
          top: 50%
          transform: translate(-50%, -50%)
          font-weight: 500

  .QuestionTooltip
    > div
      width: 100%
      height: 100%

      > div
        width: 100%
        height: 100%

        > svg
          width: 90%
          height: 100%
          opacity: 0

  :global(.DayPicker-Months)
    width: 100%

  :global(.DayPicker-Month)
    width: 100%
    margin: 0

  :global(.DayPicker-Weekday)
    padding: 10px
    text-align: left
    @extend %section
    text-transform: capitalize

    &:nth-child(6), &:nth-child(7)
      color: $color-secondary

  :global(.DayPicker-Day)
    border: $border-width solid $background-accent
    height: 86px
    width: 116px
    padding: 0
    position: relative
    color: $color-text-primary
    @extend %caption

    &:global(.DayPicker-Day--today)
      font-weight: inherit

    .Day
      position: absolute
      inset: -$border-width
      border: $border-width solid $background-accent

      &.interactive
        cursor: pointer

      &.disabled
        color: $color-text-secondary

        .DayPrice
          background: #{$background-accent}50

      &.background_blocked
        background: $blocked-color

        &.connector_background:after
          background: $blocked-color

      &.background_completed
        background: $completed-color

        &.connector_background:after
          background: $completed-color

      &.background_active
        background: $active-color

        &.connector_background:after
          background: $active-color

      &.border_pending
        border: $border-width solid $background-primary
        z-index: 2

        &:not(.connector_background).connector_border:after
          background: $background-default

        &.connector_border
          &:after
            border-top: $border-width solid $background-primary
            border-bottom: $border-width solid $background-primary

      &.border_multiple_pending
        border: $border-width solid $color-hint-warning
        z-index: 2

        &:not(.connector_background).connector_border:after
          background: $background-default

        &.connector_border
          &:after
            border-top: $border-width solid $color-hint-warning
            border-bottom: $border-width solid $color-hint-warning

      &.connector
        &:after
          content: ''
          display: block
          position: absolute

          height: calc(100% + 2 * #{$border-width})
          width: $border-width

          top: 0
          left: -$border-width

          border-top: $border-width solid $background-default
          border-bottom: $border-width solid $background-default
          transform: translateY(-#{$border-width})

          +mediamax(xs)
            width: 60%

            left: calc(-50% - #{$border-radius-xs})
            transform: translate(50%, -#{$border-width})

      &.priority
        z-index: 100

      .DayNumber
        position: absolute
        top: 8px
        left: 8px

      .DayPrice
        position: absolute
        top: 6px
        right: 6px
        padding: 2px 4px
        border-radius: 4px
        background: $background-accent

      .Blocking__desktop
        position: absolute
        inset: -$border-width
        z-index: 2
        background-image: url("border.png")
        background-size: 100% 100%

        & > *
          position: absolute
          left: 50%
          top: 60%
          transform: translate(-50%, -50%)
          padding: 0
          width: 24px
          height: 24px
          pointer-events: none

      .Blocking__mobile
        position: absolute
        inset: $border-width
        border: $border-width dashed $color-text-secondary
        border-radius: $border-radius-xs

        > div
          display: none

        &__active
          background-color: $color-primary
          z-index: -1

.ActionWrapper
  z-index: 100
  position: sticky
  bottom: 0
  left: 0
  +stack(0, row, center, center)

  &__mobile
    position: fixed
    width: 100%

  .Action
    max-width: 600px
    padding: 24px
    background: $background-floating
    border-radius: $border-radius-md
    flex: 1 1 auto
    align-items: center

    &__mobile
      flex-direction: column-reverse
      background: $background-default

      > button
        width: 100%

    & > *
      flex: 1 1 0

    .ActionInfo
      @extend %caption
      color: $color-text-secondary
