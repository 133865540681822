@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.Content
    @extend %section

    max-width: 498px
    gap: 0
    p
        margin: 12px 0

    ul
        @extend %body
        margin: 12px 0
        padding: 0 8px
        list-style-type: "•"

        li
            padding-left: 4px

            &:not(:last-of-type)
                margin-bottom: 16px

    &.Mobile
        @extend %body
        ul li:not(:last-of-type)
            margin-bottom: 0
