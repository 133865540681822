@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

$day-size: 36px

@mixin day($color)
  position: relative
  &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)):after
    content: ''
    display: block
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: $day-size
    height: $day-size
    border-radius: $border-radius-xs
    background-color: $color
    z-index: -1

@mixin daysConnector($color)
  &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)):before
    content: ''
    display: block
    position: absolute
    left: max(calc((100% - #{$day-size}) / 2 + #{$border-radius-xs}), 1px)
    top: 50%
    transform: translate(-100%, -50%)
    width: 100%
    height: $day-size
    background-color: $color
    z-index: -2

.wrapper
  :global(.DayPicker-Day--blue)
    @include day(#C5ECFF)

    &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)) + :global(.DayPicker-Day--blue)
      @include daysConnector(#C5ECFF)

  :global(.DayPicker-Day--yellow)
    @include day(#F8E47A)

    &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)) + :global(.DayPicker-Day--yellow)
      @include daysConnector(#F8E47A)

  :global(.DayPicker-Day--pink)
    @include day(#F8A07A)

    &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)) + :global(.DayPicker-Day--pink)
      @include daysConnector(#F8A07A)

  :global(.DayPicker-Day--green)
    @include day(#C8F87A)

    &:not(:global(.DayPicker-Day--disabled), :global(.DayPicker-Day--outside)) + :global(.DayPicker-Day--green)
      @include daysConnector(#C8F87A)

  :global(.DayPicker-Day--disabled):after
    content: unset !important


  :global(.DayPicker-Day__active):after
    content: ''
    position: absolute
    bottom: 13px
    left: 50%
    transform: translateX(-50%)
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: $background-primary


.price
  @extend %body
  margin-left: 20px

  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-areas: "item-0 item-2" "item-1 item-3"

  &__item
    margin-top: 16px
    overflow: hidden
    & > [class*=question-tooltip]
      margin: 0

  &__item_content
    display: flex
    align-items: start

  &__square
    width: 24px
    height: 24px
    border-radius: 4px
    margin-right: 8px
    flex-shrink: 0

    &--blue
      background-color: #C5ECFF

    &--yellow
      background-color: #F8E47A

    &--pink
      background-color: #F8A07A

    &--green
      background-color: #C8F87A
