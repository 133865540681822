@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.frame
   border: none
   width: 100%
   height: 100vh

.loaderWrapper
   display: flex
   align-items: center
   justify-content: center

