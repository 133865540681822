@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.Form
    width: 100%
    max-width: 328px
    display: flex
    flex-direction: column
    align-items: center

    .Header
        @extend %headline
        text-align: center
    .SubHeader
        @extend %body
        text-align: center


    .InputOtpWrapper
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

    .OtpWaiting
        margin-top: 44px
        max-width: 176px
        @extend %body
        color: $color-text-secondary
