@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"


.hotel_edit_form
  &__comment
    @extend %footnote
    border: 2px solid $color-hint-warning
    padding: 20px 24px
    border-radius: $border-radius-md

  &__phone
    display: flex
    width: 100%
    & > div
      margin-left: -24px

  &__description textarea
    overflow-y: auto

  &__modal_body
    padding: 0 108px
    +mediamax(xs)
      padding: 58px

    &__search
      position: sticky
      top: 28px

    &__title
      @extend %title
      text-align: center
      margin: 56px 0 16px

    &__content
      margin: 0 -24px

