@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.company-info
  &__title
    @extend %title
    padding: 24px 24px 2px
  &__alignment-wrap
    padding: 8px 24px 0
    &:last-of-type
      margin-bottom: 0
  &__field-alert
    display: flex
    align-items: start
    gap: 8px
    margin-bottom: 0
    margin-top: 12px

    svg
      flex-shrink: 0

    span
      @extend %caption
      color: $color-text-secondary
      margin-top: 2px
      +mediamax(md)
        margin-top: 4px

  &__form
    max-width: 532px
    margin: auto
    .input--form, [class*=multi_multi_menu__noHover]
      +mediamax(xs)
        padding-left: 24px
        padding-right: 24px
    [class*=multi_multi_menu__noHover]
      .input--form
        padding: 0

.input-wrapper__finance
  .input__icon
    cursor: default
    &:hover:not(.disabled):not([disabled])
      background: transparent

.success-email
  color: $color-text-primary

.success-message
  max-width: 370px

.fields-grid
  display: grid
  grid-template-columns: repeat(2, 1fr)

.fields-grid__authority
  display: grid
  grid-template-columns: repeat(3, 1fr)

.fields-grid__mt
  margin-top: -16px

.success-steps-message
  text-align: center
  margin: 216px auto 0
  &>div
    width: auto
