@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.item
    +stack(18px, row)

    &:not(:last-of-type)
        padding-bottom: 24px
        border-bottom: 2px solid $background-accent

    .content
        +stack(14px)
        flex: 1 1 auto
        .title
            @extend %body

        .description
            @extend %footnote
            a
                text-decoration: none
                color: $color-secondary

    .date
        @extend %caption
        color: $color-text-secondary
