@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

$transition: 0.3s

.notification
  transform: translateX(100%)
  transition: transform ease $transition
  margin-bottom: 20px
  &:last-of-type
    margin-bottom: 0

  &__view
    transform: translateX(-32px)
    transition: transform ease $transition

    +mediamax(xs)
      transform: translateX(0px)

  &__wrapper
    position: fixed
    bottom: 32px
    right: 0
    z-index: 100050

    +mediamax(xs)
      bottom: 4px
      left: 20%

  &__content
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding: 9px 12px
    max-width: 380px
    background: $color-default
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.04)
    border-radius: $border-radius-sm
    border-bottom-right-radius: 0

  &__body
    @extend %footnote
    flex-grow: 1
    word-break: break-word
    a
      color: $color-default

  &__action
    @extend %body
    cursor: pointer
    color: $color-text-primary
    margin: 0 0 0 16px
    padding: 4px
    background-color: rgba($background-black, 0.1)
    border-radius: 50%
    
    > svg
      width: 12px
      height: 12px

    &:hover
      background-color: rgba($background-black, 0.2)

  &__success,
  &__error
    .notification__body
      color: $color-default

    .notification__action
      background-color: rgba($background-floating, 0.1)

      &:hover
        background-color: rgba($background-floating, 0.2)

      svg path
        fill: $color-default

  &__success .notification__content
    background-color: $color-hint-success

  &__error .notification__content
    background-color: $color-hint-warning
