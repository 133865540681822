@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"

$feedback-attachment-border-width: 2px
$feedback-attachment-preview-size: 82px

.feedback

  &__filters
    display: flex
    align-items: center
    flex-grow: 1

    > div
      width: auto
      padding: 0

    .button
      margin-right: 12px

    .feedback__loader-wrapper
      padding: 24px

    .input--form
      width: auto
      margin: 16px 24px
      padding: 8px 2px 8px 16px
      border: 2px solid $color-primary
      border-radius: $border-radius-sm
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05)

      .input__input
        font-weight: 500
        font-size: 16px
        line-height: 24px

      .button
        margin-right: 0

        &:hover
          background: none

  &__loader-wrapper
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding: 100px 0

  &__modal-header
    padding: 48px 0 36px
    text-align: center

  &__modal-title
    @extend %title
    text-decoration: none

  &__modal-subheader
    @extend %footnote
    margin-top: 4px
    color: $color-text-secondary

  &__order
    &-wrapper
      margin: 16px 0 32px

      .acc__item
        &-button
          margin: 0
          padding: 0

        &-title
          @extend %body

    &-row
      padding: 16px 0 16px

    &-label
      @extend %caption
      color: $color-text-secondary
      margin-bottom: 2px

  &__message
    margin: 32px 0 16px

    &-with-attachments
      & >div>div:nth-child(2)
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0

    &_accent
      background-color: $background-accent

    &:first-of-type
      margin-top: 0

    &-textarea
      padding: 16px

    textarea
      caret-color: $color-text-primary

  &__file-upload
    margin-bottom: 24px

  &__checkbox .checkbox-new
    padding-left: 0
    padding-right: 0

  &__attachments
    padding: 0 16px 16px 16px
    border-radius: 0 0 20px 20px
    background-color: $background-accent

  &__attachment
    display: flex
    width: 100%
    max-width: 348px
    overflow: hidden

    border: $feedback-attachment-border-width solid $background-default
    box-sizing: border-box
    border-radius: $border-radius-xs

    &:not(:last-child)
      margin-bottom: 8px

    &-preview
      flex-shrink: 0
      height: $feedback-attachment-preview-size
      width: $feedback-attachment-preview-size

      background:
        position: center
        repeat: no-repeat
        color: $background-default

    &-preview-image
      background-size: cover

    &-content
      position: relative
      flex: 1 1 auto
      padding: 16px
      border-left: $feedback-attachment-border-width solid $background-default
      overflow: hidden

    &-title
      flex: 1 1 auto
      padding-right: 28px

      @extend %body
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    &-link
      position: absolute
      top: 6px
      right: 4px

      flex-shrink: 0

    &-info
      margin-top: 8px

      @extend %caption
      color: $color-text-secondary
