@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.RatingsCardWrapper
  margin: 0 2px
  padding: 0

  +mediamax(xs)
    margin-top: -26px
    > div
      gap: 8px

.MoreButtonMobile
  position: absolute
  top: 16px
  right: 16px
