@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.info-hotel__container .input__input
    color: $color-text-tertiary

.info-hotel__container
    &__title
        @extend %section
        margin: 40px 24px 8px

    &__description
        @extend %footnote
        color: $color-text-secondary
        margin: 0 24px

    &__edit-button
        padding: 32px 24px 0
