@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.timetable
  margin: 24px -16px

  +mediamax(xs)
    margin: 0

  &__disabled
    pointer-events: none

  &__add
    margin-top: 8px
    margin-left: -16px
    margin-bottom: -8px

  &__item
    @extend %section
    margin-bottom: 24px
    border: 2px solid $background-accent
    border-radius: $border-radius-md
    padding: 32px 40px
    &:last-child
      margin-bottom: 24px

      +mediamax(xs)
        margin-bottom: 8px
        padding: 32px 16px

    &-title
      @extend %section
      margin-bottom: 24px

    &-delete
      position: absolute
      right: 24px
      top: 16px
      z-index: 1

    &-content
      margin: 0 -24px
      padding-bottom: 8px
      position: relative
      &:first-of-type
        margin-top: -16px

      &:last-of-type
        padding-bottom: 0

    &-error
      @extend %footnote
      display: block
      margin-top: 16px
      color: $color-hint-error
