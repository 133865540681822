@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.action_button_container
    margin-top: 32px
    +mediamax(xs)
        margin: 16px -8px 0px

.restaurant_tags__label
    display: flex
    align-items: center
