@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.Wrapper
    position: absolute
    z-index: 10
    top: 0
    padding-bottom: 24px

    :global(.ScrollbarsCustom)
        max-height: 344px

.Menu
    position: relative
    border-radius: $border-radius-md

    background: $background-floating
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04)
    overflow: hidden

    &:after
        content: ""
        background: linear-gradient(0deg, $background-floating 0.30%, transparent)
        width: 100%
        display: block
        position: absolute
        height: 24px
        bottom: 0
        left: 0
        pointer-events: none

    &.Empty
        padding-bottom: 16px
        &:after
            display: none
.Search
    max-width: 312px
    padding: 16px 24px 0

    :global(.input--single)
        border: 2px solid $color-primary
        input
            transform: translate(2px, -2px)

.List
    :global(.radio-button__item):not(:only-of-type):first-of-type
        padding-top: 32px

