@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.tourProgram__desc
  margin: 0 -24px

.actions
  margin: -16px -24px
  cursor: pointer

.dayItem

  &:not(:last-child)
    margin-bottom: 79px

  .day
    margin: 0px 0 16px 0

    .daysNum 
      @extend %headline

  .dayName
      @extend %title