@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.container
    display: grid
    grid-template-columns: auto auto auto
    grid-template-rows: 198px 198px
    grid-auto-flow: row
    grid-auto-flow: column dense
    gap: 12px
    margin: 24px 0 40px

.item 
    grid-row: span 2
    grid-column: span 2
    border-radius: 16px
    background-color: $background-accent
    background-repeat: no-repeat
    background-position: center
    background-size: cover

    &__small
        grid-row: span 1
        grid-column: span 1

        &__blackout
            display: flex
            align-items: center
            justify-content: center

        &__lastImage
            text-align: center
            position: absolute
            height: 100%
            width: 100%
            position: relative
            display: flex
            align-items: center
            justify-content: center

            &__blackout
                filter: brightness(0.5)

        &__text
            @extend %body
            position: absolute
            color: $background-floating
            text-align: center

