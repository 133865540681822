@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography.sass"
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

:global(.modal-window-right__action-button)
    z-index: 1

:global(.modal-window-right__action-button__container)
    max-width: 648px

.loader
    height: 100%
    display: grid
    place-items: center

.header
    padding: 48px 0 36px
    text-align: center

.title
    @extend %title

.subtitle
    @extend %footnote
    margin-top: 4px
    color: $color-text-secondary
