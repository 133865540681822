@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.ModerationInfo
    display: flex
    align-items: center
    gap: 8px

    padding: 14px 24px
    border-radius: $border-radius-md
    border: 2px solid $color-hint-warning

    @extend %body

    svg
        flex-shrink: 0
