@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.RatingCardDisputeContainer
  +mediamin(md)
    max-width: 450px !important

.textFiled
  +mediamin(sm)
    padding: 0

.FieldFileUploadWrapper
  > div
    display: block
    padding: 14px

    > div
      margin-bottom: 6px
