@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"

.hint
  @extend %footnote
  color: $color-text-secondary

.error
  @extend %footnote
  color: $color-hint-error
