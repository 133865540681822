@import "@russpass/partner-front-ui/build/assets/styles/common/vars.sass"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

$tab-padding: 12px

.navbar
  width: 100%
  margin-bottom: -2px
  position: absolute
  left: 0
  bottom: 0
  padding: 0 32px

  +mediamax(md)
    position: relative
    padding: 0 24px

  +mediamax(sm)
    position: relative
    padding: 0

  &__link
    color: $color-text-secondary
    @extend %body
    padding: $tab-padding
    position: relative
    white-space: nowrap
    transition: color .2s
    text-decoration: none
    display: inline-block
    box-sizing: border-box
    cursor: pointer

    &:first-child
      padding-left: 0

      &::after
        width: calc(100% - #{$tab-padding})
        left: 0

    &:last-child
      padding-right: 0

      &::after
        width: calc(100% - #{$tab-padding})
        right: 0

      &.navbar__link_alert:before
        right: -8px

    &:only-child
      &::after
        width: 100%

    &::after
      content: ""
      width: calc(100% - 2 * #{$tab-padding})
      position: absolute
      bottom: 0
      left: $tab-padding
      height: 2px
      background-color: transparent
      display: block

    &_active_main
      color: $color-text-primary

      &::after
        background-color: $color-primary

    &_active_secondary
      color: $color-hint-warning

      &::after
        background-color: $color-hint-warning

    &_alert
      position: relative

      &:before
        position: absolute
        right: - 8px + $tab-padding
        content: ''
        width: 6px
        aspect-ratio: 1/1
        border-radius: 50%
        background: $color-hint-warning

    &__error
      background: $color-hint-error
      width: 6px
      height: 6px
      border-radius: 50%
      display: block
      position: absolute
      top: 11px
      right: 4px

    &_with_tooltip
      &:last-child .navbar__link_tooltip_wrapper > div
        transform: translateX(calc(50% + 12px))
        +mediamax(xs)
          transform: translateX(calc(50% + 20px))

      +mediamax(xs)
        padding-left: 20px
        padding-right: 20px
        &::after
          width: calc(100% - 40px)
          left: 20px

        &:first-child, &:last-child
          &::after
            width: calc(100% - 20px)

    &_tooltip_wrapper
      position: absolute
      right: 2px
      top: -2px
      display: flex

      & > div
        transform: translateX(50%)
        margin: 0

      +mediamax(xs)
        right: 10px