@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'

.Rating
    background: $color-text-secondary
    color: $color-default
    padding: 4px 6px
    border-radius: $border-radius-xs
    width: 40px
    height: 40px
    @extend %caption
    display: flex
    align-items: center
    justify-content: center

    &.Low
        background: $color-hint-warning
    &.High
        background: $color-hint-success
