@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import '@russpass/partner-front-ui/build/assets/styles/common/typography'
@import '@russpass/partner-front-ui/build/assets/styles/mixins/mixin-stack'

.list
    width: 824px
    max-width: 100%
    margin: 8px auto

    +stack(40px)

    .item
        width: 100%

        +stack(16px, row)

        .content
            overflow: hidden
            flex: 1 1 auto
            +stack(12px)

        .title
            @extend %title

            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden

        .types
            +stack(4px)

            .type
                @extend %body
                color: $color-text-secondary

        .button
            margin-top: -6px

