@import "@russpass/partner-front-ui/build/assets/styles/common/vars"
@import "@russpass/partner-front-ui/build/assets/styles/common/typography"
@import "@russpass/partner-front-ui/build/assets/styles/generic/grid"

.content
  display: flex
  flex-wrap: nowrap
  align-items: center
  gap: 8px
  +mediamax(xs)
    align-items: flex-end
    border-radius: $border-radius-md $border-radius-md 0 0
    border: 2px solid $background-accent-transparency

    :global(.file-upload__control)
      order: 1
      margin-left: 6px
      margin-bottom: 8px

.supportLink
  text-decoration: none
  color: $color-secondary

.blockedChat
  @extend %caption
  grid-gap: 8px
  color: $color-text-secondary
  display: flex
  flex-direction: row
  align-items: center
  padding: 12px 0

.textarea
  padding-right: 0
  padding-left: 0
  +mediamax(xs)
    order: 2

  label
    display: none
  textarea
    @extend %body

.send_button
  +mediamax(xs)
    background: none
    order: 3
    margin-right: 6px
    margin-bottom: 8px

.attachments_wrapper
  position: relative
  +mediamax(xs)
    padding: 0 16px 9px
    overflow-x: auto

.attachments
  padding: 16px 0 0 0
  display: flex
  gap: 12px
  overflow: hidden
  position: relative
  +mediamax(xs)
    overflow: visible

  &__file
    @extend %caption
    border: 2px solid $background-accent
    border-radius: $border-radius-sm
    width: 180px
    min-width: 180px
    position: relative
    display: flex
    gap: 12px
    align-items: center
    transition: left 0.5s ease-in-out
    span
      display: block
      white-space: nowrap
      width: 100%
      overflow: hidden
      text-overflow: ellipsis
      padding-right: 8px
    :global(.button__icon)
      position: absolute
      top: -7px
      right: -8px
      pointer-events: auto
      cursor: pointer

    &_prefix
      background-color: $background-accent
      width: 40px
      border-radius: $border-radius-sm 0 0 $border-radius-sm
      padding: 6px
      svg
        opacity: 0.3

    &__error
      margin-top: 8px

  &__btn
    position: absolute
    top: 16px
    left: -12px
    display: flex
    background-color: $background-floating
    width: 40px
    height: 40px
    border-radius: $border-radius-sm
    cursor: pointer
    pointer-events: auto
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05)
    +mediamax(xs)
      display: none
    &--right
      left: auto
      right: -12px
      transform: scaleX(-1)
